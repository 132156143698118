<template>
  <div>
    <b-card v-if="details">
      <b-row>
        <b-col
          md="6"
          lg="6"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('title') }}
          </h6>
          <p>
            {{ details.title_label }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('author') }}
          </h6>
          <p>
            {{ details.author.name }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('status') }}
          </h6>
          <p>
            {{ details.status_value }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('views') }}
          </h6>
          <p>
            {{ details.views }}
          </p>
          <hr>
        </b-col>
        <b-col
          v-if="details.keywords && details.keywords.length"
          md="4"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('keywords') }}
          </h6>
          <div class="d-flex align-items-center">
            <p
              v-for="(item,index) in details.keywords"
              :key="index"
            >
              {{ item }}, &nbsp;
            </p>
          </div>
          <hr>
        </b-col>
        <b-col
          md="12"
          lg="12"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('description') }}
          </h6>
          <div v-html="details.description[$i18n.locale]"></div>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          lg="3"
        >
          <h6 class="custom-line-clamp-one">
            {{ $t('image') }}
          </h6>
          <div>
            <img
              :src="details.image_path"
              class="img-fluid mb-2"
              style="max-height: 400px; object-fit: cover; width:100%"
              alt=""
            >
          </div>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'blogs-ratings',
      singularName: 'blogs-ratings',
      details: null,
      searchFields: {
        blog_id: this.$route.params.blog_id,
      },
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.axios.get(`/blogs/${this.$route.params.blog_id}`)
        .then(res => {
          this.details = res.data.data
        })
    },
  },
}
</script>
